import { openLoadingIndicator } from "./openLoadingIndicator";

//This method is usually better...
export async function wrapPromiseWithLoader<T>(arg: Promise<T> | (() => Promise<T>), opts?: { timeoutMS?: number }): Promise<T> {
  const prom = typeof arg === "function" ? arg() : arg;

  return promiseFetcher(prom, opts);
}

async function promiseFetcher(prom: Promise<any>, opts?: { onError?: () => void; timeoutMS?: number }) {
  let cleanup = () => {};

  try {
    const TIMEOUT = "TIMEOUT";
    const timeoutProm = opts?.timeoutMS ? new Promise<"TIMEOUT">(res => setTimeout(() => res(TIMEOUT), opts.timeoutMS)) : null;
    const jankRaceVal = await Promise.race([new Promise<"TIMEOUT">(res => setTimeout(() => res(TIMEOUT), 500)), prom]);

    //Only show loading indicator if takes longer than 500 ms
    if (jankRaceVal === TIMEOUT) {
      const loader = openLoadingIndicator();
      cleanup = () => loader.close(); //in case things fail

      if (opts?.timeoutMS) {
        const timeoutPromVal = await Promise.race([timeoutProm, prom]);
        if (timeoutPromVal === TIMEOUT) {
          throw new Error("Promise fetcher timed out");
        }
      }
    }

    await prom;
    cleanup();
  } catch (e) {
    cleanup();
    if (opts?.onError) {
      console.error(e);
      opts.onError();
      return null;
    } else {
      throw e;
    }
  }

  return await prom;
}
// i18n certified - complete
