import { translate } from "@ollie-sports/i18n";
import { OrgCoupon, OrgCouponType } from "@ollie-sports/models";
import moment from "moment";

export function isOrgCouponExpired(orgCoupon: OrgCoupon) {
  return !!(orgCoupon.expirationDateMS && orgCoupon.expirationDateMS < moment().valueOf());
}

export function isOrgCouponOutOfUses(orgCoupon: OrgCoupon) {
  return !!(orgCoupon.useLimit && orgCoupon.numberTimesUsed && orgCoupon.numberTimesUsed >= orgCoupon.useLimit);
}

export function calculateDiscountFromOrgCoupon(p: { orgCoupon: OrgCoupon; originalAmountCents: number }) {
  if (p.orgCoupon.type === OrgCouponType.amountOff) {
    if (p.orgCoupon.amountCents > p.originalAmountCents) {
      return p.originalAmountCents;
    } else {
      return p.orgCoupon.amountCents;
    }
  } else {
    return p.originalAmountCents * p.orgCoupon.percent;
  }
}

export function canUseCouponInfo(p: {
  orgCoupon: OrgCoupon;
  playerBundleId: string;
  locale: string;
}): { canUse: true } | { canUse: false; prettyMessage: string } {
  if (p.orgCoupon.expirationDateMS && p.orgCoupon.expirationDateMS < Date.now()) {
    return {
      canUse: false,
      prettyMessage: translate(
        { defaultMessage: "Coupon {code} has expired", serverLocale: p.locale },
        { code: p.orgCoupon.code }
      )
    };
  }
  if (p.orgCoupon.playerBundleIds && !p.orgCoupon.playerBundleIds[p.playerBundleId]) {
    return {
      canUse: false,
      prettyMessage: translate({
        defaultMessage: "You are not allowed to use this coupon for this player",
        serverLocale: p.locale
      })
    };
  }
  if (!p.orgCoupon.useLimit) {
    return { canUse: true };
  }

  if (p.orgCoupon.playerBundleIds) {
    if ((p.orgCoupon.numberTimesUsedByPlayerBundleId?.[p.playerBundleId] || 0) < p.orgCoupon.useLimit) {
      return { canUse: true };
    } else {
      return {
        canUse: false,
        prettyMessage: translate(
          { defaultMessage: "You have already reached the maximum use limit for coupon {code}" },
          { code: p.orgCoupon.code }
        )
      };
    }
  } else {
    if ((p.orgCoupon.numberTimesUsed ?? 0) < p.orgCoupon.useLimit) {
      return { canUse: true };
    } else {
      return {
        canUse: false,
        prettyMessage: translate({ defaultMessage: "Coupon {code} has no uses remaining" }, { code: p.orgCoupon.code })
      };
    }
  }
}
