import { COLORS, formatMoneyCentsToDollarCentPrettyString, getRefundAvailabilityDetails } from "@ollie-sports/core";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { OrgInvoice, OrgPayment, OrgPaymentInvoiceDefault, OrgPaymentRefund, OrgPaymentType } from "@ollie-sports/models";
import { useState } from "react";
import { View } from "react-native-web";
import { Form, PrettyCoolTextInputWithLabel } from "../components/Form";
import { StyledText } from "../components/StyledText";
import { FullScreenModal } from "../components/modals/getFullscreenModal";
import { getCurrentUserAccountId } from "../hooks/commonDataUtils";
import { openErrorToast } from "./openErrorToast";
import { openModal } from "../components/modals/imperativeModal";
import { getBifrost } from "../services/bifrost.service";
import getAlert from "../components/modals/getAlert";

interface PaymentRefundModalProps {
  payment: OrgPaymentInvoiceDefault;
  previousRefunds: OrgPaymentRefund[];
  onComplete?: () => Promise<void> | void;
}

export function openPaymentRefundModal(p: PaymentRefundModalProps) {
  const modal = openModal({
    body: (
      <PaymentRefundModal
        {...p}
        onRequestDismiss={() => {
          modal.close();
        }}
      />
    )
  });
}

function PaymentRefundModal(
  p: PaymentRefundModalProps & {
    onRequestDismiss: () => void;
  }
) {
  const [note, setNote] = useState("");
  const [refundAmountCentsStr, setRefundAmountCentsStr] = useState<undefined | string>();
  const [errorMsg, setErrorMsg] = useState("");

  const refundAmountCents = refundAmountCentsStr ? Math.round(parseFloat(refundAmountCentsStr) * 100) : 0;

  const { maxPossibleRefundCents } = getRefundAvailabilityDetails({ payment: p.payment, refunds: p.previousRefunds });

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Refund Payment?" })}
            bottomButton={{
              title: translate({ defaultMessage: "Refund", description: "The verb, NOT the noun" }),
              enabled: isFormValid,

              onPress: async () => {
                try {
                  if (!p.payment.nmiPaymentResponseInfo) {
                    // Payment TODO
                    return;
                  }
                  if (!note || !refundAmountCents) {
                    throw new Error("Must include note and refund amount");
                  }

                  const { data: result } = await getBifrost().orgPayment__server__issueRefund.fetchServer({
                    amountCents: refundAmountCents,
                    orgId: p.payment.orgId,
                    orgPaymentId: p.payment.id,
                    reason: note,
                    selfAccountId: getCurrentUserAccountId()
                  });
                  if (result.status === "failed") {
                    setErrorMsg(result.prettyFailureReason);
                    return;
                  }
                  await p.onComplete?.();
                  p.onRequestDismiss();
                } catch (e) {
                  setErrorMsg(
                    translate(
                      {
                        defaultMessage:
                          "There was a problem refunding the payment. Please try again or contact support@olliesports.com regarding payment id #{paymentId}"
                      },
                      { paymentId: p.payment.id }
                    )
                  );
                }
              }
            }}
            onRequestDismiss={p.onRequestDismiss}
          >
            <PrettyCoolTextInputWithLabel
              style={{ marginTop: 16 }}
              label={translate({ defaultMessage: "Refund Amount" })}
              placeholder={translate(
                { defaultMessage: "Max refund of {amount}" },
                { amount: formatMoneyCentsToDollarCentPrettyString(maxPossibleRefundCents) }
              )}
              infoTooltip={translate({
                defaultMessage:
                  "Cannot be greater than the amount the unrefunded amount remaining that customer was charged BEFORE any processing fees."
              })}
              value={refundAmountCentsStr}
              inputProps={{
                type: "number",
                min: 0,
                max: (maxPossibleRefundCents / 100).toString()
              }}
              isRequired
              onChange={newVal => {
                const newValInCents = newVal ? parseFloat(newVal) * 100 : 0;

                if (newValInCents > maxPossibleRefundCents || newValInCents < 0 || newVal?.match(/\.\d\d\d+/)) {
                  return;
                }

                setRefundAmountCentsStr(newVal);
              }}
            />

            <PrettyCoolTextInputWithLabel
              style={{ marginTop: 16 }}
              label={translate.common.Note}
              value={note}
              placeholder={translate({ defaultMessage: "Reason for refund..." })}
              isRequired
              onChange={newVal => {
                setNote(newVal ?? "");
              }}
            />

            <View style={{ marginTop: 16 }}>
              <StyledText>
                {translate(
                  {
                    defaultMessage:
                      "We will transfer {amount} out of your account and refund the customers olriginal form of payment."
                  },
                  { amount: formatMoneyCentsToDollarCentPrettyString(refundAmountCents || 0) }
                )}
              </StyledText>
            </View>
            {errorMsg ? (
              <div className="mt-2">
                <StyledText style={{ color: COLORS.red }}>{errorMsg}</StyledText>
              </div>
            ) : null}
          </FullScreenModal>
        );
      }}
    />
  );
}
