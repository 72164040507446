import {
  AccountId,
  OrgFeeDetails,
  OrgInvoice,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPayment,
  OrgPaymentInvoice,
  OrgPaymentInvoiceCredit,
  OrgPaymentType,
  OrgSettings,
  PaymentMethodSnapshot,
  PaymentMethodType
} from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment";
import { calculateFeesForPayment } from "./payment-utils";
import { customNanoid } from "./nanoid-utils";
import { translate } from "@ollie-sports/i18n";

export function generateOrgPaymentId() {
  return customNanoid(12);
}

export type OrgPaymentDetailsPast = {
  type: "past";
  totalAmountPaidCents: number;
  subtotalAmountPaidCents: number;
  memo: string;
  baseAmountCents: number;
  lateFeeAmountCents: number;
  otherFeesAmountCents: number;
  accountId: AccountId;
  paymentDateMS: number;
  paymentMethodSnapshot?: PaymentMethodSnapshot;
};

export type OrgPaymentDetailsScheduled = {
  type: "scheduled";
  totalAmountDueCents: number;
  subtotalAmountDueCents: number;
  memo: string;
  baseAmountDueCents: number;
  lateFeeAmountDueCents: number;
  lateFeeAmountDueIfLateCents: number;
  estimatedFeesAmount: number;
  accountId: AccountId;
  creditAmountCents: number;
  scheduledDateMS: number;
};

export function getOrgPaymentDetails(p: {
  orgPayment?: OrgPaymentInvoice;
  orgInvoice: OrgInvoice;
  parentOrgInvoice: OrgInvoiceParent;
  feeDetails?: OrgSettings["customFeeDetails"];
  orgPaymentInvoiceCredits: OrgPaymentInvoiceCredit[];
  paymentMethodType?: PaymentMethodType;
}): OrgPaymentDetailsPast | OrgPaymentDetailsScheduled {
  if (p.orgPayment) {
    return {
      type: "past",
      totalAmountPaidCents: _.sum([
        p.orgPayment.amountCents,
        p.orgPayment.lateFeeAmountCents ?? 0,
        p.orgPayment.processingFeeAmountCents ?? 0
      ]),
      subtotalAmountPaidCents: _.sum([p.orgPayment.amountCents, p.orgPayment.lateFeeAmountCents ?? 0]),
      memo: `${p.orgInvoice.memo}${p.orgInvoice.id !== p.parentOrgInvoice.id ? ` (${p.parentOrgInvoice.memo})` : ""}`,
      baseAmountCents: p.orgPayment.amountCents,
      lateFeeAmountCents: p.orgPayment.lateFeeAmountCents ?? 0,
      otherFeesAmountCents: p.orgPayment.processingFeeAmountCents ?? 0,
      accountId: p.orgPayment.type === OrgPaymentType.invoiceCredit ? p.orgPayment.appliedByAccountId : p.orgPayment.accountId,
      paymentDateMS: p.orgPayment.createdAtMS,
      paymentMethodSnapshot:
        p.orgPayment.type === OrgPaymentType.invoiceDefault || p.orgPayment.type === OrgPaymentType.invoiceFailedPayment
          ? p.orgPayment.paymentMethodSnapshot
          : undefined
    };
  } else {
    const creditAmountCents = _.sum(
      p.orgPaymentInvoiceCredits.filter(op => op.invoiceId === p.orgInvoice.id).map(op => op.amountCents)
    );
    const lateFeeAmountDueCents =
      p.orgInvoice.lateFeeCentsToBeIssuedIfLate && p.orgInvoice.dueDateMS < moment().valueOf()
        ? p.orgInvoice.lateFeeCentsToBeIssuedIfLate
        : 0;
    const subtotal = Math.max(_.sum([p.orgInvoice.amountDueCents, lateFeeAmountDueCents]) - creditAmountCents, 0);
    const estimatedFees = calculateFeesForPayment({
      chargeAmountCents: subtotal,
      feeDetails: p.feeDetails?.[p.paymentMethodType ?? PaymentMethodType.card],
      paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
    });
    return {
      type: "scheduled",
      totalAmountDueCents: _.sum([subtotal, estimatedFees]),
      subtotalAmountDueCents: subtotal,
      memo: `${p.orgInvoice.memo}${p.orgInvoice.id !== p.parentOrgInvoice.id ? ` (${p.parentOrgInvoice.memo})` : ""}`,
      baseAmountDueCents: p.orgInvoice.amountDueCents,
      lateFeeAmountDueCents,
      lateFeeAmountDueIfLateCents: p.orgInvoice.lateFeeCentsToBeIssuedIfLate ?? 0,
      estimatedFeesAmount: estimatedFees,
      accountId:
        p.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
        p.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
          ? p.orgInvoice.accountIdScheduledToPay
          : "",
      creditAmountCents,
      scheduledDateMS:
        p.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
        p.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
          ? p.orgInvoice.autoChargeDateMS
          : p.orgInvoice.createdAtMS
    };
  }
}

export function filterOrgPaymentInvoices(orgPayments: OrgPayment[]) {
  return orgPayments.filter(
    op =>
      op.type === OrgPaymentType.invoiceCredit ||
      op.type === OrgPaymentType.invoiceDefault ||
      op.type === OrgPaymentType.invoiceFailedPayment
  ) as OrgPaymentInvoice[];
}
