import { translate } from "@ollie-sports/i18n";
import { PaymentMethod, PaymentMethodSnapshot, PaymentMethodType } from "@ollie-sports/models";

export function getPaymentMethodToUse(paymentMethodsById: Record<string, PaymentMethod>) {
  const paymentMethodsArray = Object.values(paymentMethodsById);
  return paymentMethodsArray.length ? paymentMethodsArray.find(a => a.isDefault) ?? paymentMethodsArray[0] : undefined;
}

export function getPaymentMethodString(p: {
  paymentMethod: PaymentMethod | PaymentMethodSnapshot;
  locale: string;
  includeName: boolean;
}) {
  return p.paymentMethod.type === PaymentMethodType.bank
    ? translate(
        { defaultMessage: `Bank ending in {last4}`, serverLocale: p.locale },
        { last4: p.paymentMethod.accountNumberLast4 }
      )
    : translate(
        { defaultMessage: `Card ending in {last4}`, serverLocale: p.locale },
        { last4: p.paymentMethod.cardNumberLast4 }
      ) + (p.includeName ? ` (${p.paymentMethod.ownerName})` : "");
}
