import {
  AccountId,
  AccountSecretPaymentMethodId,
  NMIResponseCodes,
  OrgCoupon,
  OrgCouponId,
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceTypes,
  OrgPaymentInvoiceDefault,
  OrgPaymentInvoiceFailed,
  OrgPaymentType,
  PaymentMethod,
  PaymentMethodSnapshot,
  PaymentMethodType,
  PlayerBundleId,
  NMIPaymentResponseInfo,
  isKeyOfNMIResponseCodes,
  NMIPaymentResponseInfo__Success,
  NMIPaymentResponseInfo__Failure
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { BatchTask } from "@ollie-sports/firebase";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../internal-utils/server-auth";
import { generateOrgPaymentId } from "./org-payment-utils";
import { OlliePipe } from "@ollie-sports/pipe";
import { getDefaultPaymentAccount } from "../compute/account.compute";
import { first } from "lodash";
import axios, { AxiosResponse } from "axios";
import { SendMessageToSlackChannel } from "./slack-utils";
import { nmiSDK } from "./nmiSDK";

export type ChargeCardUnexpectedErrorStatusCodes =
  | "charge-card-for-invoice-completely-mysterious-error"
  | "charge-card-completely-mysterious-error"
  | "charge-card-no-payment-method"
  | "charge-card-payment-method-not-found-in-basis-theory"
  | "charge-card-failed-invoice-batch-tasks-error"
  | "charge-card-invoice-success-batch-tasks-error"
  | "charge-card-creating-new-customer-without-account"
  | "charge-card-finding-or-creating-customer"
  | "charge-card-processing-error"
  | "charge-card-coupon-update-error"
  | "charge-card-mysterious-authorize-error"
  | "charge-card-unexpected-processing-error"
  | "charge-card-missing-customer-info"
  | "not-really-unexpected-just-logging"
  | "charge-card-payment-method-not-card-or-bank-in-basis-theory";

export type PayInvoiceUnexpectedErrorStatusCodes =
  | "pay-invoice-completely-mysterious-error"
  | "pay-invoice-notifications-error"
  | "pay-invoice-failed-invoice-notifications-error"
  | "pay-invoice-cant-find-player-bundle"
  | "pay-invoice-cant-find-org"
  | "pay-invoice-cant-find-payment-plan"
  | "pay-invoice-cant-find-package"
  | "pay-invoice-batch-tasks-error"
  | "pay-individual-invoice-failed-invoice-notifications-error"
  | "pay-individual-invoice-batch-tasks-error"
  | "pay-individual-invoice-completely-mysterious-error"
  | "pay-individual-invoice-notification-error"
  | "pay-individual-invoice-already-paid";

export type RefundUnexpectedErrorStatusCodes =
  | "refund-completely-mysterious-error"
  | "refund-refund-amount-too-high-for-some-reason"
  | "refund-processing-error"
  | "refund-unknown-reason";

export async function logUnexpectedPaymentError(p: {
  errorCode: ChargeCardUnexpectedErrorStatusCodes | PayInvoiceUnexpectedErrorStatusCodes | RefundUnexpectedErrorStatusCodes;
  locationId: string;
  info: any;
  olliePipe: OlliePipe;
}) {
  try {
    const info = p.info instanceof Error ? { message: p.info.message, stack: p.info.stack } : p.info;
    await SendMessageToSlackChannel({
      message: `Unexpected payment error at ${p.locationId}!\n\n${JSON.stringify({ info }, null, 2)}`,
      channel: "#important-errors"
    });

    await p.olliePipe.emitEvent({
      type: "error-" + p.errorCode,
      payload: {
        info,
        locationId: p.locationId
      }
    });
  } catch (e) {
    SendMessageToSlackChannel({
      message: `Terribly unexpected payment error at ${p.locationId}!!!!!\n\n${e}`,
      channel: "#important-errors"
    }).catch(err => console.error(err));

    p.olliePipe.emitEvent({
      type: "error-problem-sending-unexpected-payment-error",
      payload: e
    });
  }
}

export async function chargeWithNMIForInvoice(p: {
  accountIdToBeCharged: AccountId;
  nonDefaultPaymentMethodIdToUse?: string;
  orgId: OrgId;
  baseIdempotencyKey: string;
  paymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
    appliedOrgCoupon?: OrgCoupon;
  };
  isManualChildInvoiceTrigger?: boolean;
  locale: string;
  orgInvoice: OrgInvoice;
  invoiceGroupId: OrgInvoiceId;
}): Promise<
  | { type: "error"; errorCode: ChargeCardUnexpectedErrorStatusCodes; prettyErrorReason: string }
  | {
      type: "success";
      orgPayment: OrgPaymentInvoiceDefault;
    }
  | {
      type: "failed";
      orgPayment?: OrgPaymentInvoiceFailed;
      prettyFailureReason: string;
    }
> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const nowMS = Date.now();
  try {
    const chargeResponse = await chargeWithNMI(p);

    if (chargeResponse.type === "error") {
      return chargeResponse;
    }

    if (chargeResponse.type === "zeroDollarPayment") {
      const orgPayment: OrgPaymentInvoiceDefault = {
        id: generateOrgPaymentId(),
        accountId: p.accountIdToBeCharged,
        type: OrgPaymentType.invoiceDefault,
        status: "succeeded",
        amountCents: Math.floor(p.paymentDetails.baseAmountDueCents),
        lateFeeAmountCents: p.paymentDetails.lateFeeAmountDueCents
          ? Math.floor(p.paymentDetails.lateFeeAmountDueCents)
          : undefined,
        processingFeeAmountCents: Math.floor(p.paymentDetails.otherFeesAmountDueCents),
        createdAtMS: nowMS,
        invoiceGroupId: p.invoiceGroupId,
        invoiceId: p.orgInvoice.id,
        orgId: p.orgId,
        playerBundleId: p.orgInvoice.playerBundleId
      };

      try {
        await h.OrgPayment.add({ doc: orgPayment });
      } catch (e) {
        // ERROR TODO
      }

      if (p.paymentDetails.appliedOrgCoupon) {
        try {
          await h.OrgCoupon.update({
            id: p.paymentDetails.appliedOrgCoupon.id,
            doc: {
              numberTimesUsed: (p.paymentDetails.appliedOrgCoupon.numberTimesUsed ?? 0) + 1,
              numberTimesUsedByPlayerBundleId: {
                [p.orgInvoice.playerBundleId]:
                  (p.paymentDetails.appliedOrgCoupon.numberTimesUsedByPlayerBundleId?.[p.orgInvoice.playerBundleId] ?? 0) + 1
              }
            }
          });
        } catch (e) {
          logUnexpectedPaymentError({
            errorCode: "charge-card-coupon-update-error",
            olliePipe,
            locationId: "asd234kjkd",
            info: e
          });
        }
      }

      //EARLY RETURN FOR $0 CHARGE
      return { type: "success", orgPayment };
    }

    if (chargeResponse.type === "failed") {
      const batchTasks: BatchTask[] = [];
      let orgPayment: OrgPaymentInvoiceFailed | undefined = undefined;
      if (p.orgInvoice.type !== OrgInvoiceTypes.registration) {
        orgPayment = {
          id: generateOrgPaymentId(),
          accountId: p.accountIdToBeCharged,
          type: OrgPaymentType.invoiceFailedPayment,
          amountCents: Math.floor(p.paymentDetails.baseAmountDueCents),
          lateFeeAmountCents: p.paymentDetails.lateFeeAmountDueCents
            ? Math.floor(p.paymentDetails.lateFeeAmountDueCents)
            : undefined,
          processingFeeAmountCents: Math.floor(p.paymentDetails.otherFeesAmountDueCents),
          createdAtMS: nowMS,
          invoiceGroupId: p.invoiceGroupId,
          invoiceId: p.orgInvoice.id,
          orgId: p.orgId,
          playerBundleId: p.orgInvoice.playerBundleId,
          nmiPaymentResponseInfo: chargeResponse.nmiResponseData,
          status: "failed",
          paymentMethodSnapshot: chargeResponse.paymentMethodSnapshot
        };

        batchTasks.push(
          await h.OrgPayment.add(
            {
              doc: orgPayment
            },
            { returnBatchTask: true }
          )
        );
      }

      if (
        (p.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
          p.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
        !p.isManualChildInvoiceTrigger
      ) {
        const updatedOrgInvoice: OrgInvoiceChild = { ...p.orgInvoice };
        const numPreviousFailedAttempts = updatedOrgInvoice.numberOfFailedPaymentAttempts ?? 0;
        updatedOrgInvoice.previousFailedPaymentAttemptMS = nowMS;
        updatedOrgInvoice.numberOfFailedPaymentAttempts = numPreviousFailedAttempts + 1;
        if (numPreviousFailedAttempts < 4) {
          updatedOrgInvoice.failedPaymentScheduledRetryMS = moment().add(1, "day").valueOf();
        }

        batchTasks.push(
          await h.OrgInvoice.update(
            {
              id: p.orgInvoice.id,
              doc: updatedOrgInvoice
            },
            { returnBatchTask: true }
          )
        );
      }
      try {
        await h._BatchRunner.executeBatch(batchTasks);
      } catch (e) {
        logUnexpectedPaymentError({
          errorCode: "charge-card-failed-invoice-batch-tasks-error",
          olliePipe,
          info: e,
          locationId: "234kdjfkj45"
        });
        return {
          type: "error",
          prettyErrorReason: translate({
            defaultMessage:
              "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
            serverLocale: p.locale
          }),
          errorCode: "charge-card-failed-invoice-batch-tasks-error"
        };
      }
      //EARLY RETURN FOR FAILED PAYMENT
      logUnexpectedPaymentError({
        errorCode: "not-really-unexpected-just-logging",
        olliePipe,
        info: chargeResponse,
        locationId: "sadof7asdof7osad7f"
      });
      return { type: "failed", orgPayment, prettyFailureReason: chargeResponse.prettyFailureReason };
    }

    const orgPayment: OrgPaymentInvoiceDefault = {
      id: generateOrgPaymentId(),
      accountId: p.accountIdToBeCharged,
      type: OrgPaymentType.invoiceDefault,
      status: "succeeded",
      amountCents: Math.floor(p.paymentDetails.baseAmountDueCents),
      lateFeeAmountCents: p.paymentDetails.lateFeeAmountDueCents ? Math.floor(p.paymentDetails.lateFeeAmountDueCents) : undefined,
      processingFeeAmountCents: Math.floor(p.paymentDetails.otherFeesAmountDueCents),
      createdAtMS: chargeResponse.createdAtMS,
      invoiceId: p.orgInvoice.id,
      invoiceGroupId: p.invoiceGroupId,
      orgId: p.orgId,
      playerBundleId: p.orgInvoice.playerBundleId,
      nmiPaymentResponseInfo: chargeResponse.nmiPaymentInfo,
      paymentMethodSnapshot: chargeResponse.paymentMethodSnapshot
    };

    const batchTasks: BatchTask[] = [
      await h.OrgPayment.add(
        {
          doc: orgPayment
        },
        { returnBatchTask: true }
      )
    ];

    if (p.paymentDetails.appliedOrgCoupon) {
      batchTasks.push(
        await h.OrgCoupon.update(
          {
            id: p.paymentDetails.appliedOrgCoupon.id,
            doc: {
              numberTimesUsed: (p.paymentDetails.appliedOrgCoupon.numberTimesUsed ?? 0) + 1,
              numberTimesUsedByPlayerBundleId: {
                [p.orgInvoice.playerBundleId]:
                  (p.paymentDetails.appliedOrgCoupon.numberTimesUsedByPlayerBundleId?.[p.orgInvoice.playerBundleId] ?? 0) + 1
              }
            }
          },
          { returnBatchTask: true }
        )
      );
    }

    try {
      await h._BatchRunner.executeBatch(batchTasks);
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "charge-card-invoice-success-batch-tasks-error",
        olliePipe,
        info: e,
        locationId: "34ikj34kj4rtytmncx"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-invoice-success-batch-tasks-error"
      };
    }

    return { type: "success", orgPayment };
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "charge-card-for-invoice-completely-mysterious-error",
      olliePipe,
      info: e,
      locationId: "kjk6n54nmcdei"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "charge-card-for-invoice-completely-mysterious-error"
    };
  }
}

export async function chargeWithNMI(p: {
  accountIdToBeCharged: AccountId;
  nonDefaultPaymentMethodIdToUse?: AccountSecretPaymentMethodId;
  orgId: OrgId;
  baseIdempotencyKey: string;
  paymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
    appliedOrgCoupon?: OrgCoupon;
  };
  locale: string;
  note?: string;
  testPaymentOverrideDetails?: {
    paymentMethodToUse: PaymentMethod;
  };
}): Promise<
  | {
      type: "success";
      nmiPaymentInfo: NMIPaymentResponseInfo__Success;
      createdAtMS: number;
      paymentMethodSnapshot?: PaymentMethodSnapshot;
    }
  | {
      type: "failed";
      prettyFailureReason: string;
      paymentMethodSnapshot?: PaymentMethodSnapshot;
      nmiResponseData: NMIPaymentResponseInfo__Failure;
    }
  | { type: "error"; errorCode: ChargeCardUnexpectedErrorStatusCodes; prettyErrorReason: string }
  | { type: "zeroDollarPayment" }
> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  try {
    const totalAmountCents =
      p.paymentDetails.baseAmountDueCents +
      (p.paymentDetails.lateFeeAmountDueCents ?? 0) +
      p.paymentDetails.otherFeesAmountDueCents;

    if (totalAmountCents < 1) {
      //EARLY RETURN FOR $0 CHARGE
      return { type: "zeroDollarPayment" };
    }

    let paymentMethodToUse: PaymentMethod | null | undefined = undefined;
    if (p.testPaymentOverrideDetails) {
      paymentMethodToUse = p.testPaymentOverrideDetails.paymentMethodToUse;
    } else {
      const [accountSecret] = await Promise.all([h.AccountSecret.getDoc(p.accountIdToBeCharged)]);

      paymentMethodToUse = p.nonDefaultPaymentMethodIdToUse
        ? accountSecret?.paymentMethodsById?.[p.nonDefaultPaymentMethodIdToUse]
        : getDefaultPaymentAccount(Object.values(accountSecret?.paymentMethodsById ?? {}));
    }

    if (!paymentMethodToUse) {
      logUnexpectedPaymentError({
        errorCode: "charge-card-no-payment-method",
        olliePipe,
        info: null,
        locationId: "k34j5kjs8234kljKDkjer"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage: "Cannot find a payment method. Ensure you have added a payment method.",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-no-payment-method"
      };
    }

    const doRetryingPaymentRequest = (count = 0): Promise<NMIPaymentResponseInfo> => {
      return (
        paymentMethodToUse.type === PaymentMethodType.card
          ? nmiSDK.chargeCC({
              amountCents: totalAmountCents,
              chargingOrgId: p.orgId,
              encryptedCardNumber: paymentMethodToUse.encryptedCardNumber,
              expMM: paymentMethodToUse.expMM,
              expYYYY: paymentMethodToUse.expYYYY,
              fullName: paymentMethodToUse.ownerName,
              postalCode: paymentMethodToUse.postalCode,
              idempotencyKey: p.baseIdempotencyKey + paymentMethodToUse.encryptedCardNumber
            })
          : nmiSDK.chargeBankAccount({
              accountType: paymentMethodToUse.bankAccountType,
              amountCents: totalAmountCents,
              chargingOrgId: p.orgId,
              encryptedAccountNumber: paymentMethodToUse.encryptedAccountNumber,
              fullName: paymentMethodToUse.ownerName,
              routingNumber: paymentMethodToUse.routingNumber,
              idempotencyKey: p.baseIdempotencyKey + paymentMethodToUse.encryptedAccountNumber + paymentMethodToUse.routingNumber
            })
      ).catch(async e => {
        if (count === 0) {
          await new Promise(res => setTimeout(res, 1000));
          return doRetryingPaymentRequest(1);
        } else {
          throw e;
        }
      });
    };

    try {
      var nmiResponseData = await doRetryingPaymentRequest();
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "charge-card-unexpected-processing-error",
        locationId: "k3j4lkjfd980234kmaserkop",
        olliePipe,
        info: e
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "There was an error processing your payment. You have not been charged. Please try again. If the issue persists, please contact us at support@olliesports.com",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-unexpected-processing-error"
      };
    }

    if (nmiResponseData.status === "success") {
      return {
        type: "success",
        createdAtMS: Date.now(),
        nmiPaymentInfo: nmiResponseData,
        paymentMethodSnapshot:
          paymentMethodToUse.type === PaymentMethodType.bank
            ? { ...paymentMethodToUse, encryptedAccountNumber: null }
            : { ...paymentMethodToUse, encryptedCardNumber: null }
      };
    } else if (nmiResponseData.response === 2) {
      // fail
      let prettyFailureReason = translate({
        defaultMessage: "There was a problem processing your payment",
        serverLocale: p.locale
      });

      const responseCode = nmiResponseData.responseCode;

      const possiblePrettyError = responseCode
        ? possiblyConvertNMIResponseCodeToTranslatedPrettyString({
            locale: p.locale,
            responseCode
          })
        : undefined;
      if (possiblePrettyError) {
        prettyFailureReason += ` (${possiblePrettyError})`;
      }
      prettyFailureReason += ".";
      prettyFailureReason += ` ${translate({
        defaultMessage: "You have not been charged. Please update your payment method and try again.",
        serverLocale: p.locale
      })}`;

      await olliePipe.emitEvent({
        type: "error-charging-with-nmi",
        payload: {
          accountId: p.accountIdToBeCharged,
          nmiInfo: nmiResponseData,
          prettyFailureReason,
          paymentMethodToUse,
          paymentDetails: p.paymentDetails
        }
      });

      return {
        type: "failed",
        prettyFailureReason,
        paymentMethodSnapshot:
          paymentMethodToUse.type === PaymentMethodType.bank
            ? { ...paymentMethodToUse, encryptedAccountNumber: null }
            : { ...paymentMethodToUse, encryptedCardNumber: null },
        nmiResponseData
      };
    } else {
      // Error
      logUnexpectedPaymentError({
        errorCode: "charge-card-unexpected-processing-error",
        olliePipe,
        info: nmiResponseData,
        locationId: "lk23j4lkjlkjazsdf934"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "There was an error processing your payment. You have not been charged. Please try again. If the issue persists, please contact us at support@olliesports.com",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-unexpected-processing-error"
      };
    }
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "charge-card-completely-mysterious-error",
      olliePipe,
      info: e,
      locationId: "234kljwlkehmnb56n6b7980809"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "charge-card-completely-mysterious-error"
    };
  }
}

export function possiblyConvertNMIResponseCodeToTranslatedPrettyString(p: { responseCode?: number; locale: string }) {
  if (!p.responseCode) {
    return translate.common.Unknown;
  }
  let prettyFailureReason = "";
  if (
    p.responseCode === 201 ||
    p.responseCode === 204 ||
    p.responseCode === 260 ||
    p.responseCode === 261 ||
    p.responseCode === 262 ||
    p.responseCode === 263 ||
    p.responseCode === 264
  ) {
    prettyFailureReason = translate({ defaultMessage: "Declined", serverLocale: p.locale });
  } else if (p.responseCode === 202) {
    prettyFailureReason = translate({ defaultMessage: "Insufficient funds", serverLocale: p.locale });
  } else if (p.responseCode === 203) {
    prettyFailureReason = translate({ defaultMessage: "Over limit", serverLocale: p.locale });
  } else if (p.responseCode === 220) {
    prettyFailureReason = translate({ defaultMessage: "Incorrect payment information", serverLocale: p.locale });
  } else if (p.responseCode === 223) {
    prettyFailureReason = translate({ defaultMessage: "Card expired", serverLocale: p.locale });
  } else if (p.responseCode === 224) {
    prettyFailureReason = translate({ defaultMessage: "Invalid expiration", serverLocale: p.locale });
  } else if (p.responseCode === 225) {
    prettyFailureReason = translate({ defaultMessage: "Invalid CVV", serverLocale: p.locale });
  } else {
    prettyFailureReason = translate({ defaultMessage: "Unknown processing error", serverLocale: p.locale });
  }

  return prettyFailureReason;
}
