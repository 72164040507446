import { formatMoneyCentsToDollarCentPrettyString } from "@ollie-sports/core";
import { translate } from "@ollie-sports/i18n";
import { OrgInvoice, OrgPaymentType } from "@ollie-sports/models";
import { useState } from "react";
import { View } from "react-native-web";
import { Form, PrettyCoolTextInputWithLabel } from "../components/Form";
import { StyledText } from "../components/StyledText";
import { FullScreenModal } from "../components/modals/getFullscreenModal";
import { getCurrentUserAccountId } from "../hooks/commonDataUtils";
import { getBifrost } from "../services/bifrost.service";
import { openErrorToast } from "./openErrorToast";
import { openModal } from "../components/modals/imperativeModal";

interface ForgivePaymentModalProps {
  orgInvoice: OrgInvoice;
  remainingAmount: number;
  onComplete: () => Promise<void>;
  forgiveChildren?: boolean;
}

export function openPaymentForgivenessModal(p: ForgivePaymentModalProps) {
  return new Promise<string | undefined>(res => {
    const modal = openModal({
      body: (
        <ForgivePaymentModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
          }}
        />
      )
    });
  });
}

function ForgivePaymentModal(
  p: ForgivePaymentModalProps & {
    onRequestDismiss: () => void;
  }
) {
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Forgive Payment?" })}
            bottomButton={{
              title: translate.common.Apply,
              enabled: isFormValid && !isLoading,

              onPress: async () => {
                setIsLoading(true);
                try {
                  if (!note) {
                    throw new Error("");
                  }
                  if (p.forgiveChildren) {
                    await getBifrost().orgInvoice__client__forgiveParentAndChildren.fetchClient({
                      appliedByAccountId: getCurrentUserAccountId(),
                      parentOrgInvoiceId: p.orgInvoice.id,
                      note
                    });
                  } else {
                    await getBifrost().orgPayment__client__addOrgPaymentInvoiceCredits.fetchClient({
                      orgPaymentInvoiceCredits: [
                        {
                          type: OrgPaymentType.invoiceCredit,
                          appliedByAccountId: getCurrentUserAccountId(),
                          note,
                          orgId: p.orgInvoice.orgId,
                          status: "succeeded",
                          amountCents: p.remainingAmount,
                          invoiceGroupId: p.orgInvoice.invoiceGroupId,
                          invoiceId: p.orgInvoice.id,
                          playerBundleId: p.orgInvoice.playerBundleId
                        }
                      ]
                    });
                  }
                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem applying the credit. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              if (
                note &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <View>
                <StyledText>
                  {translate(
                    { defaultMessage: "If you forgive this payment, a credit for {amount} will be generated." },
                    { amount: formatMoneyCentsToDollarCentPrettyString(p.remainingAmount) }
                  )}
                </StyledText>
              </View>

              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Note}
                value={note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
              />
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
