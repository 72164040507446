import { translate } from "@ollie-sports/i18n";
import toast from "react-hot-toast";

export function openErrorToast(msg: string) {
  toast.error(msg);
}

export function openSuccessToast(msg?: string) {
  toast.success(msg ?? translate.common.Success);
}
