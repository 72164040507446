import axiosRaw from "axios";
import { getServerHelpers } from "../helpers";
import { decryptPIFI } from "./pifi-crypto-helpers";
import type * as ioredis from "ioredis";
import { NMIPaymentResponseInfo, NMIResponseCodes, isKeyOfNMIResponseCodes } from "@ollie-sports/models";
import { SendMessageToSlackChannel } from "./slack-utils";

//Should only need a lock to perform the work of roughly 30 seconds max for all NMI endpoints
const LOCK_TIME = 30_000;

const nmiAxios = axiosRaw.create({
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  timeout: LOCK_TIME - 5000,
  transformRequest: [
    data => {
      return Object.keys(data)
        .filter(key => data[key] !== undefined)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }
  ]
});

const NMI_URL = "https://secure.nmi.com/api/transact.php";
const TEST_MERCHANT_SECURITY_KEY = "j8DVk6Kd4nj4eZs6axa59db8Xe5WU239";

export const nmiSDK = {
  async chargeCC(p: {
    chargingOrgId: string;
    fullName: string;
    expMM: string;
    expYYYY: string;
    encryptedCardNumber: string;
    amountCents: number;
    idempotencyKey: string;
    postalCode?: string;
  }) {
    return await performIdempotentWork(p.idempotencyKey, async () => {
      // SERVER_ONLY_TOGGLE
      const fullNameArr = p.fullName.trim().split(/\s+/);
      const first_name = fullNameArr.length > 1 ? fullNameArr.slice(0, -1).join(" ") : p.fullName;
      const last_name = fullNameArr.length > 1 ? fullNameArr.pop() : "";
      const nmiSecret = await getOrgNMISecret(p.chargingOrgId);
      const isDev = getServerHelpers().serverConfig.projectId.match(/dev/i);
      const paymentResp = await nmiAxios.post(NMI_URL, {
        security_key: nmiSecret,
        type: "sale",
        test_mode: isDev ? "enabled" : undefined,
        ccnumber: await decryptPIFI(p.encryptedCardNumber),
        ccexp: p.expMM + p.expYYYY.slice(2),
        amount: centsToStringDollars(p.amountCents),
        first_name,
        last_name,
        zip: p.postalCode
      });

      if (!paymentResp || !paymentResp.data) {
        throw new Error("Malformed response from NMI! No response data found");
      }

      const info = parseNMIResponseData(paymentResp.data);

      if (info.response === 3) {
        throw new Error("Problem charging card with NMI! \n" + JSON.stringify(info, null, 2));
      }

      return info;
    });
    // SERVER_ONLY_TOGGLE
  },
  async chargeBankAccount(p: {
    chargingOrgId: string;
    fullName: string;
    amountCents: number;
    routingNumber: string;
    encryptedAccountNumber: string;
    accountType: "savings" | "checking";
    idempotencyKey: string;
  }) {
    // SERVER_ONLY_TOGGLE
    return await performIdempotentWork(p.idempotencyKey, async () => {
      const nmiSecret = await getOrgNMISecret(p.chargingOrgId);
      const paymentResp = await nmiAxios.post(NMI_URL, {
        security_key: nmiSecret,
        type: "sale",
        payment: "check",
        test_mode: getServerHelpers().serverConfig.projectId.match(/dev/i) ? "enabled" : undefined,
        amount: centsToStringDollars(p.amountCents),
        checkaba: p.routingNumber,
        checkaccount: await decryptPIFI(p.encryptedAccountNumber),
        checkname: p.fullName,
        account_type: p.accountType
      });

      if (!paymentResp || !paymentResp.data) {
        throw new Error("Malformed response from NMI! No response data found");
      }

      const info = parseNMIResponseData(paymentResp.data);

      if (info.response === 3) {
        throw new Error("Problem charging bank with NMI! \n" + JSON.stringify(info, null, 2));
      }

      return info;
    });
    // SERVER_ONLY_TOGGLE
  },
  async refundTransaction(p: {
    refundingOrgId: string;
    transactionId: string;
    type: "check" | "creditcard";
    amountCents: number;
    idempotencyKey: string;
  }) {
    // SERVER_ONLY_TOGGLE
    return await performIdempotentWork(p.idempotencyKey, async () => {
      const isDev = getServerHelpers().serverConfig.projectId.match(/dev/i);
      const nmiSecret = await getOrgNMISecret(p.refundingOrgId);
      const paymentResp = await nmiAxios.post(NMI_URL, {
        security_key: nmiSecret,
        type: "refund",
        amount: centsToStringDollars(p.amountCents),
        transactionid: p.transactionId,
        test_mode: isDev ? "enabled" : undefined,
        payment: p.type
      });

      if (!paymentResp || !paymentResp.data) {
        throw new Error("Malformed response from NMI! No response data found");
      }

      const info = parseNMIResponseData(paymentResp.data);

      if (info.response === 3) {
        if (info.responseText.includes("must be settled")) {
          // Handle a special case. Must detect this in the calling function
          return info;
        }
        throw new Error("Problem refunding transaction in NMI! \n" + JSON.stringify(info, null, 2));
      }

      return info;
    });
    // SERVER_ONLY_TOGGLE
  },
  async verifyCC(p: { fullName: string; number: string; expMM: string; expYYYY: string; cvv?: string; postalCode?: string }) {
    // SERVER_ONLY_TOGGLE
    const isDev = getServerHelpers().serverConfig.projectId.match(/dev/i);
    const fullNameArr = p.fullName.trim().split(/\s+/);
    const first_name = fullNameArr.length > 1 ? fullNameArr.slice(0, -1).join(" ") : p.fullName;
    const last_name = fullNameArr.length > 1 ? fullNameArr.pop() : "";
    const authorizeResp = await nmiAxios.post(NMI_URL, {
      security_key: getServerHelpers().serverConfig.nmiOllieMerchantAccountSecret,
      type: "auth",
      ccnumber: p.number,
      ccexp: p.expMM + p.expYYYY.slice(2),
      cvv: p.cvv,
      amount: isDev ? "1.00" : "0.01",
      first_name,
      last_name,
      zip: p.postalCode,
      test_mode: isDev ? "enabled" : undefined
    });

    if (!authorizeResp || !authorizeResp.data) {
      throw new Error("Malformed response from NMI! No response data found");
    }

    const authorizeInfo = parseNMIResponseData(authorizeResp.data);

    if (authorizeInfo.response === 3) {
      throw new Error("Problem authorizing NMI! \n" + JSON.stringify(authorizeInfo, null, 2));
    } else if (authorizeInfo.response !== 1) {
      // Card was declined. Return what we know
      return authorizeInfo;
    }

    const doRetryingVoid = (count = 0): Promise<NMIPaymentResponseInfo> => {
      return nmiAxios
        .post(NMI_URL, {
          security_key: getServerHelpers().serverConfig.nmiOllieMerchantAccountSecret,
          type: "void",
          transactionid: authorizeInfo.transactionId,
          payment: "creditcard",
          test_mode: isDev ? "enabled" : undefined
        })
        .then(async a => {
          const info = parseNMIResponseData(a.data);

          if (count < 5 && info.response !== 1) {
            return await doRetryingVoid(count + 1);
          } else {
            return info;
          }
        })
        .catch(async e => {
          if (count > 5) {
            throw e;
          } else {
            await new Promise(res => setTimeout(res, 1000));
            return doRetryingVoid(count + 1);
          }
        });
    };

    const voidInfo = await doRetryingVoid();

    if (voidInfo.response === 3) {
      throw new Error("Problem voiding NMI! \n" + JSON.stringify(voidInfo, null, 2));
    }

    return voidInfo;
    // SERVER_ONLY_TOGGLE
  },

  async verifyConnectivityForOrgId(orgId: string) {
    // SERVER_ONLY_TOGGLE
    try {
      const resp = await nmiAxios.post("https://secure.networkmerchants.com/api/query.php", {
        security_key: await getOrgNMISecret(orgId),
        result_limit: 1
      });

      const hasErrorTag = typeof resp.data === "string" ? !!resp.data.match(/\berror_response\b/) : false;

      return resp.status >= 200 && resp.status < 300 && !hasErrorTag;
    } catch (e) {
      return false;
    }
    // SERVER_ONLY_TOGGLE
  }
};

async function getOrgNMISecret(orgId: string) {
  // SERVER_ONLY_TOGGLE
  if (getServerHelpers().serverConfig.projectId.match(/dev/i)) {
    //Always return test merchant in dev firestore environment.
    return TEST_MERCHANT_SECURITY_KEY;
  }

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const orgSecret = await h.OrgSecret.getDoc(orgId);
  if (!orgSecret) {
    throw new Error("Org id does not exist! " + orgId);
  }

  if (!orgSecret.nmiConfig) {
    throw new Error("Org does not have NMI credentials set! " + orgId);
  }

  return decryptPIFI(orgSecret.nmiConfig.encryptedMerchantApiKey);
  // SERVER_ONLY_TOGGLE
}

function parseNMIResponseData(data: string): NMIPaymentResponseInfo {
  // SERVER_ONLY_TOGGLE
  const parsedData = data.split("&").reduce((acc, pair) => {
    let [key, value] = pair.split("=");
    acc[key] = decodeURIComponent(value || "");
    return acc;
  }, {} as Record<string, any>);

  const responseCode = parseInt(parsedData["response_code"]);

  const response = parseInt(parsedData["response"]);

  if (response === 1) {
    if (!parsedData["transactionid"]) {
      throw new Error("Unable to detect transaction id in NMI response! " + data);
    }
    return {
      status: "success",
      response,
      responseCode,
      transactionId: parsedData["transactionid"],
      responseText: parsedData["responsetext"],
      responseCodeText: isKeyOfNMIResponseCodes(responseCode) ? NMIResponseCodes[responseCode] : ""
    };
  } else {
    return {
      status: "failure",
      response,
      responseCode,
      transactionId: parsedData["transactionid"],
      responseText: parsedData["responsetext"],
      responseCodeText: isKeyOfNMIResponseCodes(responseCode) ? NMIResponseCodes[responseCode] : ""
    };
  }
  // SERVER_ONLY_TOGGLE
}

let client: ioredis.Redis = null as any;
async function performIdempotentWork(key: string, doWork: () => Promise<NMIPaymentResponseInfo>) {
  // SERVER_ONLY_TOGGLE
  if (!client) {
    const { ioredis } = getServerHelpers().injectedServerLibraries;
    client = new ioredis.Redis();
  }

  const prevResp = await client.get(`nmi_response:${key}`);

  if (prevResp) {
    return JSON.parse(prevResp);
  }

  const start = Date.now();

  try {
    const lock = await client.set(`nmi_lock:${key}`, "locked", "PX", LOCK_TIME, "NX");

    //Handle if there's no response but it can't obtain a lock. E.g. Two requests arrived almost simultaneously.
    if (!lock) {
      //Note that this while loop will always terminate the function since it will end with with either a recursive retry or with a saved response from an other thread.
      while (true) {
        //Polling is fine in this case. Will usually just be a couple polls (max of 30) and it's way simpler than a Redis PubSub subscription
        await new Promise(res => setTimeout(res, 1000));
        const [pollResp, currLock] = await Promise.all([client.get(`nmi_response:${key}`), client.get(`nmi_lock:${key}`)]);

        if (pollResp) {
          return JSON.parse(pollResp);
        }

        if (!currLock) {
          //The thread that had the lock errored out or expired, so try to gain our own lock and retry
          return performIdempotentWork(key, doWork);
        }
      }
    }

    const resp = await doWork();

    if (Date.now() - start >= LOCK_TIME) {
      //Should never happen since since doWork would have errored out by now due to a request timeout since its been LOCK_TIME. But just in case...
      await SendMessageToSlackChannel({
        channel: "#important-errors",
        message: "Idempotent NMI payment calls took longer than 30 seconds!"
      });

      return resp;
    }

    //Should only save the response if successful AND only for 24 hours
    if (resp.status === "success") {
      await client.set(`nmi_response:${key}`, JSON.stringify(resp), "EX", 60 * 60 * 24);
    }
    await client.del(`nmi_lock:${key}`);
    return resp;
  } catch (e) {
    await client.del(`nmi_lock:${key}`);
    throw e;
  }
  // SERVER_ONLY_TOGGLE
}

function centsToStringDollars(cents: number) {
  return (cents / 100).toFixed(2);
}
