import _, { values } from "lodash";
import { customNanoid } from "./nanoid-utils";
import {
  OrgFeeDetails,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgPayment,
  OrgPaymentInvoice,
  OrgPaymentInvoiceCredit,
  OrgPaymentInvoiceDefault,
  OrgPaymentRefund,
  OrgPaymentType,
  OverallInvoiceStatus,
  PaymentMethodType
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import moment from "moment";
import { calculateFeesForPayment } from "./payment-utils";
import { filterOrgPaymentInvoices } from "./org-payment-utils";

export function generateOrgInvoiceId() {
  return customNanoid(12);
}

type IndividualInvoiceStatus = "paid" | "partial" | "failed" | "pastDue" | "scheduled" | "unpaid";

export function getIndividualOrgInvoiceStatus(p: { orgInvoice: OrgInvoice }): IndividualInvoiceStatus {
  if (p.orgInvoice.thisInvoicePaidInFullDateMS) {
    return "paid";
  }
  if (p.orgInvoice.derivedTotalAmountPaidCentsBeforeAllFees && !isChildOrgInvoice(p.orgInvoice)) {
    return "partial";
  }
  if (
    (p.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
      p.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
    p.orgInvoice.numberOfFailedPaymentAttempts
  ) {
    return "failed";
  }
  if (p.orgInvoice.dueDateMS < moment().valueOf()) {
    return "pastDue";
  }
  if (isChildOrgInvoice(p.orgInvoice)) {
    return "scheduled";
  }
  return "unpaid";
}

export function getOverallOrgInvoiceStatus(p: {
  parentOrgInvoice: OrgInvoiceParent;
  orgInvoiceChildren: OrgInvoiceChild[];
  allOrgPayments: OrgPayment[]; //All payments linked to the parent OR children
}): OverallInvoiceStatus {
  const allOrgInvoices = [p.parentOrgInvoice, ...p.orgInvoiceChildren];
  if (!allOrgInvoices.find(oi => !oi.thisInvoicePaidInFullDateMS)) {
    return OverallInvoiceStatus.paid;
  }
  if (!p.parentOrgInvoice.thisInvoicePaidInFullDateMS) {
    if (p.parentOrgInvoice.dueDateMS < moment().valueOf()) {
      return OverallInvoiceStatus.late;
    } else {
      return OverallInvoiceStatus.outstanding;
    }
  }

  if (
    allOrgInvoices.find(
      oi => !oi.thisInvoicePaidInFullDateMS && !!p.allOrgPayments.find(op => op.type === OrgPaymentType.invoiceFailedPayment)
    )
  ) {
    return OverallInvoiceStatus.invoiceFailedPayment;
  }
  if (allOrgInvoices.find(oi => !oi.thisInvoicePaidInFullDateMS && oi.dueDateMS < moment().valueOf())) {
    return OverallInvoiceStatus.latePaymentInstallment;
  }

  return OverallInvoiceStatus.inProgress;
}

export function isParentOrgInvoice(orgInvoice: OrgInvoice) {
  return orgInvoice.type === OrgInvoiceTypes.manual || orgInvoice.type === OrgInvoiceTypes.registration;
}
export function isChildOrgInvoice(orgInvoice: OrgInvoice) {
  return (
    orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
    orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
  );
}

export type IndividualOrgInvoiceDetails = {
  totalAmount: number;
  paidAmount: number;
  creditAmount: number;
  remainingAmount: number;
  status: IndividualInvoiceStatus;
  lateFeesPaidAmount: number;
  otherFeesPaidAmount: number;
  subtotalRemainingAmountDueCents: number;
  lateFeesAmountDueCents: number;
  estimatedOtherFeesAmountDueCentsOnRemainingAmount: number;
  totalRemainingAmountDueCents: number;
};

export function getRefundAvailabilityDetails(p: { payment: OrgPaymentInvoiceDefault; refunds: OrgPaymentRefund[] }) {
  const totalAmountPaidCents =
    (p.payment.amountCents || 0) + (p.payment.lateFeeAmountCents || 0) + (p.payment.processingFeeAmountCents || 0);
  const amountRefundedPreviously = p.refunds.reduce((a, b) => a + b.totalAmountRefundedCents, 0);
  const maxPossibleRefundCents = totalAmountPaidCents - amountRefundedPreviously;
  const refundWindowIsOpen = moment(p.payment.createdAtMS).isAfter(moment().subtract(1, "year"));

  return {
    totalAmountPaidCents,
    amountRefundedPreviously,
    maxPossibleRefundCents,
    refundWindowIsOpen,
    canIssueRefund: maxPossibleRefundCents > 0 && refundWindowIsOpen
  };
}

export function getIndividualOrgInvoiceAmountDetails(p: {
  orgInvoice: OrgInvoice;
  orgPayments: OrgPayment[];
  feeDetails?: OrgFeeDetails;
  paymentMethodType: PaymentMethodType;
}): IndividualOrgInvoiceDetails {
  let totalAmount = p.orgInvoice.amountDueCents;
  let paidAmount = _.sum(p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault).map(op => op.amountCents));
  let creditAmount = _.sum(p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceCredit).map(op => op.amountCents));
  let lateFeesPaidAmount = _.sum(
    p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault).map(op => op.lateFeeAmountCents ?? 0)
  );
  let otherFeesPaidAmount = _.sum(
    p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceDefault).map(op => op.processingFeeAmountCents ?? 0)
  );
  let remainingAmountDueCents = totalAmount - paidAmount - creditAmount;
  let lateFeesAmountDueCents =
    !p.orgInvoice.thisInvoicePaidInFullDateMS && p.orgInvoice.dueDateMS < moment().valueOf() && !lateFeesPaidAmount
      ? p.orgInvoice.lateFeeCentsToBeIssuedIfLate
      : 0;
  const subtotalRemainingAmountDueCents = remainingAmountDueCents + lateFeesAmountDueCents;
  const estimatedOtherFeesAmountDueCentsOnRemainingAmount = calculateFeesForPayment({
    chargeAmountCents: subtotalRemainingAmountDueCents,
    feeDetails: p.feeDetails,
    paymentMethodType: p.paymentMethodType
  });
  const totalRemainingAmountDueCents = subtotalRemainingAmountDueCents + estimatedOtherFeesAmountDueCentsOnRemainingAmount;
  let status = getIndividualOrgInvoiceStatus(p);

  return {
    totalAmount,
    paidAmount,
    creditAmount,
    remainingAmount: remainingAmountDueCents,
    status,
    lateFeesPaidAmount,
    otherFeesPaidAmount,
    subtotalRemainingAmountDueCents,
    lateFeesAmountDueCents,
    estimatedOtherFeesAmountDueCentsOnRemainingAmount,
    totalRemainingAmountDueCents
  };
}
export function getOverallOrgInvoiceAmountDetails(p: {
  parentOrgInvoice: OrgInvoiceParent;
  childrenOrgInvoices: OrgInvoiceChild[];
  orgPayments: OrgPayment[];
}) {
  const pastPaidLateFeesAmount = p.orgPayments.reduce((a, b) => a + (b.lateFeeAmountCents || 0), 0);
  const orgInvoicePayments = filterOrgPaymentInvoices(p.orgPayments);

  const currentlyDueLateFeesAmount = p.childrenOrgInvoices.reduce((acc, inv) => {
    const thisInvoiceCurrLateFees =
      inv.derivedTotalAmountPaidCentsBeforeAllFees < inv.amountDueCents && inv.dueDateMS < Date.now()
        ? inv.lateFeeCentsToBeIssuedIfLate
        : 0;
    return acc + thisInvoiceCurrLateFees;
  }, 0);

  let totalAmount = p.parentOrgInvoice.derivedTotalAmountDueCentsIncludingChildrenInvoices;
  let paidAmount = [p.parentOrgInvoice, ...p.childrenOrgInvoices].reduce((acc, val, index) => {
    const orgPaymentsForInvoice = orgInvoicePayments.filter(
      op => op.invoiceId === val.id && op.type === OrgPaymentType.invoiceDefault
    );
    acc = acc + _.sum(orgPaymentsForInvoice.map(op => op.amountCents));
    return acc;
  }, 0);
  let creditAmount = [p.parentOrgInvoice, ...p.childrenOrgInvoices].reduce((acc, val) => {
    const orgPaymentsForInvoice = orgInvoicePayments.filter(
      op => op.invoiceId === val.id && op.type === OrgPaymentType.invoiceCredit
    );
    acc = acc + _.sum(orgPaymentsForInvoice.map(op => op.amountCents));
    return acc;
  }, 0);

  let status = getOverallOrgInvoiceStatus({
    allOrgPayments: orgInvoicePayments,
    orgInvoiceChildren: p.childrenOrgInvoices,
    parentOrgInvoice: p.parentOrgInvoice
  });

  let otherFeesPaidAmount = [p.parentOrgInvoice, ...p.childrenOrgInvoices].reduce((acc, val, index) => {
    const orgPaymentsForInvoice = orgInvoicePayments.filter(
      op => op.invoiceId === val.id && op.type === OrgPaymentType.invoiceDefault
    );
    acc = acc + _.sum(orgPaymentsForInvoice.map(op => op.processingFeeAmountCents ?? 0));
    return acc;
  }, 0);

  let remainingAmount = totalAmount + currentlyDueLateFeesAmount - paidAmount - creditAmount;

  return {
    totalAmount,
    paidAmount,
    creditAmount,
    remainingAmount,
    status,
    pastPaidLateFeesAmount,
    currentlyDueLateFeesAmount,
    otherFeesPaidAmount
  };
}
export function isIndividualOrgInvoiceEligibleForCreditDeletion(p: { orgInvoice: OrgInvoice; orgPayments: OrgPaymentInvoice[] }) {
  const hasPayment = !!p.orgPayments.find(op => op.invoiceId === p.orgInvoice.id && op.type === OrgPaymentType.invoiceDefault);
  const credits = p.orgPayments.filter(op => op.type === OrgPaymentType.invoiceCredit && op.invoiceId === p.orgInvoice.id);
  const totalCreditAmount = _.sum(credits.map(c => c.amountCents));
  const creditsCoverEntireAmountDue = totalCreditAmount >= p.orgInvoice.amountDueCents;
  const dueDateHasPassed = p.orgInvoice.dueDateMS < moment().valueOf();

  if (hasPayment) {
    return false;
  } else if (dueDateHasPassed && p.orgInvoice.thisInvoicePaidInFullDateMS) {
    return false;
  }
  return true;
}
