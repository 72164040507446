import { StringFormatHint } from "../soccer-logic/SoccerStatDefinitions/types";
import { msToMinutesString } from "./time-strings";

export function formatPercent(percent: number, opts?: { numDecimals?: number }) {
  const per100 = percent * 100;
  const fixed = per100.toFixed(opts?.numDecimals ? opts?.numDecimals : 0);

  return fixed + "%";
}

export function formatNumber(
  num: number,
  stringFormatHint: StringFormatHint,
  opts?: { numDecimals?: number; minutesOnly?: boolean }
) {
  switch (stringFormatHint) {
    case "ms":
      return msToMinutesString(num, opts?.minutesOnly);
    case "percent":
      return formatPercent(num);
    case "number":
      const mult = Math.pow(10, opts?.numDecimals ?? 1);
      let baseNum = Math.round(num * mult) / mult;
      if (baseNum >= mult * 10) {
        baseNum = Math.round(baseNum);
      }
      return String(baseNum);
    case "dollars":
      return formatMoneyCentsToDollarCentPrettyString(num, true);
    case "dollarsAndCents":
      return formatMoneyCentsToDollarCentPrettyString(num);
  }
}

export function formatMoneyCentsToDollarCentPrettyString(numCents: number, dollarsOnly?: boolean) {
  const dollarString = parseInt(`${numCents / 100}`)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const cents = parseInt(`${numCents % 100}`);
  return dollarsOnly ? `$${dollarString}` : `$${dollarString}.${cents < 10 ? `0${cents}` : cents}`;
}

// i18n certified - complete
